<template>
	<!--新增合同-->
	<Modal
		:title="title"
		:value="visible"
		width="500"
		:mask-closable="false"
		@on-cancel="onCancel"
		class="contract-manage-wrapper"
	>
		<Spin fix v-if="loading"></Spin>
		<Form
			:model="formData"
			ref="contractForm"
			label-position="top"
			:rules="rules"
		>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="研究成果类型" prop="isPass">
						<RadioGroup v-model="formData.templateId" vertical>
							<Radio
								:label="item.templateId"
								v-for="(item, index) in projectAchieveData"
								:key="`achieve_${index}_${item.templateId}`"
								>{{ item.templateName }}</Radio
							>
						</RadioGroup>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onSubmit">确定</Button>
		</div>
	</Modal>
</template>

<script>
import achievementApi from "@/api/achievement/achievement"

const { getProjectAchieveForm,  apiResResultDraft } = achievementApi

export default {
	name: "Add",
	components: {},
	props: {
		visible: {},
		projectId: {
			type: String,
			default: undefined
		}
	},
	data() {
		return {
			loading: false,
			projectAchieveData: [],
			title: "",
			formData: {
				templateId: ""
			},
			rules: {
				templateId: {
					required: true,
					type: "number",
					message: "研究成果不能为空",
					trigger: "blur change"
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.getProjectAchieveForm()
				this.$refs.contractForm.resetFields()
				this.formData = {
					templateId: ""
				}
				this.title = "添加研究成果"
			}
		}
	},
	methods: {
		/**
		 * 获取研究成果类型
		 */
		getProjectAchieveForm() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await getProjectAchieveForm({ projectId: this.projectId })
				if (res && res.data) {
					this.projectAchieveData = res.data
				}
				this.loading = false
			})
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onSubmit() {
			this.$refs.contractForm.validate(valid => {
				if (valid) {
					// 新增研究成果
					this.$asyncDo(async () => {
						this.loading = true
						let res = await apiResResultDraft({
							...this.formData,
							projectId: this.$route.params.projectId
						})

						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
			return true
		}
	}
}
</script>

<style lang="less">
.contract-manage-wrapper {
	margin-bottom: 20px;
	.ivu-select-input[disabled],
	.ivu-input[disabled],
	.ivu-input-number-input[disabled] {
		color: #333;
	}
	.ivu-input-number-input::placeholder {
		color: #c5c8ce;
	}
	.noDelBtn {
		.ivu-upload-list-remove {
			display: none;
		}
	}
	.cost-wrap .other-label {
		.ivu-form-item-label {
			color: #666;
			font-weight: 400;
		}
	}
}
</style>
