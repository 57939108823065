<template>
	<Modal
		:title="templateName"
		:value="visible"
		width="1000"
		@on-cancel="onCancel"
		class="contract-manage-wrapper-a"
    :footer-hide="!isEdit"
	>
		<!--研究成果 填写-->
		<div class="content-wrap" style="padding-top: 0; padding-bottom: 0" ref="asa">
			<Spin fix v-if="loading"></Spin>
					<div :style="`height:${dataGridHeight}px`" style="overflow: auto">
						<designTemplate
							:is-no-height="true"
							:updateId="projectId"
							:componentList="formData.formJson"
							ref="designTemplate_formData"
							:showType="isEdit ? 'approval' : 'history'"
						>
						</designTemplate>
					</div>
		</div>
    <template slot="footer">
      <div
        v-if="isEdit && formData.formJson && formData.formJson.length"
        style="text-align: right;padding-right: 30px;"
      >
        <Button
          style="margin-right: 18px"
          @click="onCancel"
          :loading="loading"
        >取消</Button>
        <Button style="margin-right: 18px" type="primary" @click="onConfirm(formData,1)" :loading="loading">发布草稿</Button>
        <Button type="primary" @click="onConfirm(formData,0)" :loading="loading">保存发布</Button>
      </div>
    </template>
	</Modal>
</template>

<script>
import achievementApi from "@/api/achievement/achievement"
import designTemplate from "@/components/BusinessComponents/FormDesign/designTemplate.vue"
import { mapState } from "vuex"
import moment from "moment"

const { getConfigDetailForm, apiResResultSave } = achievementApi

export default {
	name: "fillform",
	components: {
		designTemplate
	},
	props: {
		visible: {},
		isDisabled: {
			type: Boolean,
			default: false
		},
		templateId: {
			type: Number,
			default: 0
		},
		templateName: {
			type: String,
			default: ""
		},
    formDataId: {
      type: Number,
      default: 0
		},
    formJson: {}
	},
	data() {
		return {
			loading: false,
			tabList: [],
			tabName: "",
      formData: {}
		}
	},
	computed: {
		projectId() {
			return this.$route.params.projectId
		},
		...mapState({
			dataGridHeight: state => state.contentHeight - 185
		}),
		...mapState("permission", ["projectActionPermissions"]),
		isEdit() {
			return !this.isDisabled
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				// this.tabName = this.templateName
				this.init()
			}
		}
	},
	methods: {
		init() {
      this.formData = {
        templateName: this.templateName,
        projectId: this.$route.params.projectId,
        templateId: this.templateId,
      }
      if(this.formJson) {
        this.formData.formJson= this.formJson
        if (this.formData.formJson) {
          if (typeof this.formData.formJson === "object") {
            this.formData.formJson = this.formData.formJson
          } else {
            this.formData.formJson = JSON.parse(this.formData.formJson)
          }
        }
      } else {
        this.$asyncDo(async () => {
          this.loading = true
          const res = await getConfigDetailForm({ formId: this.templateId })
          if (res && res.data) {
            this.formData.formJson= res.data.json
            if (this.formData.formJson) {
              if (typeof this.formData.formJson === "object") {
                this.formData.formJson = this.formData.formJson
              } else {
                this.formData.formJson = JSON.parse(this.formData.formJson)
              }
            }
          }
          this.loading = false
        })
      }
		},
		// 取消
		onCancel() {
			this.$emit("onCancel")
		},
		// 提交
		onConfirm(item, isDraft) {
      const { $refs } = this.$refs.designTemplate_formData
        $refs.designTemplateForm.validate(isValid => {
          if (isValid) {
            this.submit('designTemplate_formData', item,isDraft)
          }
        })
		},
		submit(temp, item, isDraft) {
			this.$asyncDo(async () => {
				this.loading = true
				let json = []
				if (item.formJson && item.formJson.length) {
					json = await this.getCustomFormData(temp, item.formJson)
				}
				const params = {
					projectId: this.projectId,
          formDataId: this.formDataId,
					formJson: item.formJson ? JSON.stringify(json) : null,
          isDraft
				}
				const res = await apiResResultSave(params)
				if (res) {
					this.$emit("onOk")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			})
		},
		async getCustomFormData(temp, componentList) {
			const { list, formData } = this.$refs[temp]
			const newListArr = componentList.map(item => {
				const newItem = list.find(li => li.id === item.id)
				if (newItem) {
					newItem.isShow = true
					return newItem
				}
				return { ...item, isShow: false }
			})
			return newListArr.map(listItem => {
				let dataJson = {}
				if (listItem.data) {
					if (typeof listItem.data === "object") {
						dataJson = listItem.data
					} else {
						dataJson = JSON.parse(listItem.data)
					}
				}
				if (dataJson.bindType === "date") {
					if (formData[dataJson.code]) {
						dataJson.dataValue = moment(formData[dataJson.code]).format(
							"YYYY-MM-DD"
						)
					} else {
						dataJson.dataValue = ""
					}
				} else if (dataJson.bindType === "datetime") {
					if (formData[dataJson.code]) {
						dataJson.dataValue = moment(formData[dataJson.code]).format(
							"YYYY-MM-DD HH:mm:ss"
						)
					} else {
						dataJson.dataValue = ""
					}
				} else if (
					dataJson.type === "base_Select" &&
					dataJson.isRequest === "2"
				) {
					dataJson.dataValue = dataJson.userName || ""
				} else {
					dataJson.dataValue = formData[dataJson.code]
				}
				if (
					listItem.json &&
					(listItem.json.type === "base_Radio" ||
						listItem.json.type === "base_Checkbox")
				) {
					dataJson.optionList = listItem.json.optionList
				}
				//  赋值
				const obj = {}
				Object.keys(listItem).forEach(key => {
					if (key !== "json") {
						obj[key] = listItem[key]
					}
				})
				obj.data = JSON.stringify(dataJson)
				return obj
			})
		}
	}
}
</script>
